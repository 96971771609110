<!--
 * @Descripttion: 
 * @version: 
 * @Author: Mr. Xu
 * @Date: 2021-04-19 12:05:04
 * @LastEditors: Mr. Xu
 * @LastEditTime: 2021-04-28 16:05:00
-->
<template>
  <div class="myDiv">
    <div class="menu"></div>
    <div id="myMap"></div>
  </div>
</template>

<script>
import WSGTOBD from "./pointUtils.js";
import gcoord from "gcoord";
import axios from "../../api/axios.js"
import dayjs from "dayjs"
export default {
  components: {},
  props: {},
  data() {
    return {
      url:"/reportform_gps_trail"
    };
  },
  watch: {},
  computed: {},
  methods: {
    getGps(){
      let data = new FormData();
      data.append('child_merchant_id','上海拓华');
      data.append('imei','080001116047');
      data.append('tripid','1619307476');
      data.append('dtoken','e80e9b13eb4fccc4420facb953fd53d1');
      axios.post("/reportform_gps_trail",data).then(res =>{
        let arr = res.data.data;
        let newArr = [];
        arr.map((item,index) =>{
          let obj = {
            latitude:item.latitude,
            longtitude:item.longtitude,
            time:dayjs(item.time).valueOf()
          }
          newArr.push(obj)
        })
        console.log(newArr)
        newArr.sort((a,b)=>{
          return a.time-b.time
        })
        this.init(newArr)
      })
    },
    mapclick(e) {
      console.log(e);
      alert("点击位置经纬度：" + e.latlng.lng + "," + e.latlng.lat);
    },
    initMapRoute() {
      var map = new BMap.Map("myMap");
      this.map = map;
      map.centerAndZoom(new BMap.Point(116.328749, 40.026922), 13);
      map.enableScrollWheelZoom(true);
      var p1 = new BMap.Point(116.301934, 39.977552);
      var p2 = new BMap.Point(116.508328, 39.919141);
      var p3 = new BMap.Point(116.395, 39.935);
      var driving = new BMap.DrivingRoute(map, {
        renderOptions: { map: map, autoViewport: true },
      });
      console.log(driving)
      driving.search(p1, p2,{waypoints:[p3]});
      // driving.search("天安门", "中关村",{waypoints:[p3]});
    },
    addClick() {
      this.map.addEventListener("click", this.mapclick(e));
      
    },
    init(gpsdata) {
      var map = new BMap.Map("myMap");
      this.map = map;
      map.centerAndZoom(new BMap.Point(116.328749, 40.026922), 13);
      map.enableScrollWheelZoom(true);

      gpsdata.sort((a, b) => {
        return parseInt(a.time) - parseInt(b.time);
      });
      console.log(gpsdata[0]);
      console.log(gpsdata[gpsdata.length - 1]);
      var pointArr = [];
      for (let b = 0; b < gpsdata.length; b++) {
        var item = gpsdata[b];
        var ob = gcoord.transform(
          [item.longtitude, item.latitude],
          gcoord.WGS84, // 当前坐标系
          gcoord.BD09 // 目标坐标系
        );
        // console.log(ob)
        var gpsObj = WSGTOBD({
          lon: item.longtitude,
          lat: item.latitude,
        });
        // console.log(gpsObj)
        var point = new BMap.Point(gpsObj.lon, gpsObj.lat);
        pointArr.push(point);
      }
      var polyLine = new BMap.Polyline(pointArr, {
        strokeColor: "blue", //设置颜色
        strokeWeight: 5, //宽度
        strokeOpacity: 1, //透明度
      });
      map.addOverlay(polyLine);
      map.setViewport(pointArr);
    },
    //添加按钮事件
    addPath(){
      path = {
        interfacePath:"",
        add:1  //这里添加一个标识，用来标记是你添加的数据
      }
      this.FormData.pathArray.push(path)
    },
    //表格改变事件
    tablEdit(){
      onblur = function(){
        row.interfacePath = cellInput.value
      }
    },
    del(index){
      this.FormData.pathArray.splice(index,1)
    },
    //然后点击确定的时候
    sureHandler(){
      //这里遍历你的数组
      let newArr = [];
      this.FormData.pathArray.map((item,index) =>{
        if(item.add){
          //这里就是你添加的
          newArr.push(item)
        }
      })
      //然后在进行操作就行了
    }
  },
  created() {},
  mounted() {
    // this.initMapRoute();
    // this.getGps()
  },
};
</script>
<style lang="scss" scoped>
.myDiv {
  height: 100%;
  #myMap {
    height: 70%;
  }
  .menu {
    height: 100px;
  }
}
</style>